import Code from '../../Code'
import FullFlow from './full_flow.png'
import ImplicitGrantRough from './implicit_grant_rough.png'
import PkceRough from './pcke_rough.png'

# _Sign in with terrylockett.ca_

## A Hands-on Approach to Understanding OAuth 2.0 PKCE Flow

As cyber security attacks become more abundant and sophisticated,
  many engineers will consider it prudent to delegate the responsibility
  of authenticating their users to purpose built third party solutions.
A such, it's quite common for web developers use some form of OAuth to 
  authenticate users of their site via social logins from providers like 
  facebook or google, or through Saas offerings like Okta or Auth0.
  
In some cases, the implementation details are abstracted away behind the APIs and SDKs, so 
  a thorough understanding of what OAuth is and how it works is usually
  not necessary in an engineer's daily work.
For some, years of productive web development can take place with a blissful understanding that 
  OAuth does not need to be understood.

And why bother - isn't auth something best left to the _real_ professionals?

Maybe you're curious and want to learn more,
  maybe you're interfacing with some 3rd party that does not have such a simple SDK, 
  or maybe you even need to roll your own implementation.

Informative aritcles abound for the unitiated who want/need to learn more about OAuth,
  but many of these articles describe OAuth in the abstract or at a relatively high level.
They sometimes lack information that could be used to glean an understanding of how the
  systems that implement the OAuth flows are actually built.

The situation isn't helpful for  software engineers are more comfortable reasoning about systems 
  from the bottom-up.
To this cohort, the implementation defines the architecture, not the other way around.
Their creedo is "_just show me the code_" and this post attempts to do that.

I'll be describing an example implementation of this OAuth 2.0 PKCE authorization flow,
  which is the modern OAuth 2.0 flow designed for single page web applications (SPAs).
If you're a software engineer who has struggled to understand how this flow works in your SPA,
  I hope that this post is useful to you.

This article assumes no prior knowlege of OAuth,
  but a basic understanding of web development and HTTP would be helpful.

The full code for this solution can be found at https://github.com/albertlockett/terry-sso

## The Use-Case (or _a gift to my brother_)

There's one important thing that all popular big tech companies have:
the "login with ..." button.
Google, Facbook, Github, Twitter, etc.
Navigate your web browser to to some podunk internet startup's app page, 
  and often they'll let you register and authenticate from with the identity from some real, respectable, tech company.

On the topic of respectable tech companies,
  https://terrylockett.ca is a website founded by my brother in 2011. 
At the time of this writing, it has just celebrated it's 10th birthday of being on the internet!
The site offers its visitors a cornicopia of content & features,
  including a W3C certification, a picture of a cat, and a variety of flash based videos.

In an effort to keep up with the big dogs, https://terrylockett.ca will now also support
  provide authentication solution for 3rd party sites.
The "login with terrylockett.ca" button will soon be all over the internet.

## Background

Web developers who put the the "login with terrylocket.ca" on their site effectively want to delegate
  the responsibility to terrylockett.ca of confirming the user is who they say they is.
Making this determination is called _authentication_.

_terrylockett.ca_
  has a great, functional, login page that follows all the best security practices.
In order delegate authentication, the user on some website somehow need to navigate to terrylockett.ca, login,
  and then have the results of that login returned to the original site.

_OAuth_ is the term the industry uses for this kind of workflow.
Sometimes people will use the term "OAuth 2" or "OAuth 2.0" when discussing this flow,
  to specify that they not talking about OAuth version 1,
  which is less secure and fell out of favor about around 2013.

### The Simplified Flow

A simplified OAuth flow goes like this:
- 1 - The browser starts the flow by sending a request to a authorization provider, 
  which is a 3rd party site that will handle the authentication (e.g terrylockett.ca).
  As part of this request, it will tell the authorization provider where to send the results
  in the form of a callback URL
- 2 - The requests ends up with the user being on the 3rd party app's login screen. 
  The user enters their credentials and clicks login. 
  On a successful login, the browser gets redirected back with a _token_*.

(*I'll discuss tokens a bit later, but for now know it's a special value that
  contains information the user's identity and their permissions.)

The flow is called the _Implicit Grant_ flow and for a long time it was the only option for
single page applications.

<img src={ImplicitGrantRough} width="800"/>


### The Problem with Implicit Grant

What could go wrong with the authorization flow we described above?
The main security issue is that there are ways that token can be intercepted. 
The way Implicit Grant is generally implemented,
  after user sucessfully authenticates the authentication server sends an HTTP 302 response with
  to the callback URL, including the token in the url
```
302 
Location=https://example.com?token=<token>
```
This will cause the browser will then make an HTTP GET request as it navigates to that location.
In this scenario, its possible the token will be stored in the browser history or
  it could be logged in the access logs that some web servers/proxies write for each request
  (not an exhaustive list of the ways the token could be exposed).

It would be more secure if the browser could somehow request the token ater being redirected
  after the login using some kind of asynchronous request.
Why don't we do this in implicit grant flow?

The reason the token was supplied in the URL as part of this flow is because, at the time
  the flow was designed, CORS was not widely supported
  (this request would be a cross-origin).
Luckily CORS is now widely supported and we're free to make asynchronous requests from our domain
  to the authorization server to our hearts' content (or until we get 429).

### Securing the Token Exchange with Authorization Grant Flow

What if implicit grant was enhanced to send back a special _code_
  (in the redirect URL) that could be exchanged for a token 
  in an aysnchronous post request.
Would that solve the problem?

It could help a bit, but it doesnt fully solve it.
That code could be intercepted just as easily as the token in implicit grant flow.

If only there was some special secret that the client knew a head of time that it could send with the code.
The authorization server could then verify that that the secret is correct and associated with the code.
E.g. the client could send the verification secret to prove that it is one who is really allowed to exchange the code for the token.

We're describing here is something called the _Authorization Grant_ flow,
and it's a generally the solution you would use if your front-end had a web server that
could exchange the code for the token, instead of having this exchange request happen in
the browser.

In some literature this exchange that happens between our site's webserver
and the authorization server is said to happen on the _back channel_ whereas requests
that are happening in the browser are said to happen on the _front channel_.

The problem with this flow in the context of a single page web app
  is that there might not be a web-server to make the exchange.
Users who use static web-hosting would have such a limitation.
For these scenarios, the exchange must happen on the front-channel.

But for this to work, the browser needs to know the secret,
  but if we send the secret to the browser, before the user is fully authenticated it means
  the secret is effectively public.
Anyone with the ability to inspect the site's network requests in dev tools could steal it
  and then it would provide no protection of the code/token exchange.

But what if there wasn't one static secret?
We could generate the secret dynamically in the browser...

### PKCE - Building on Authorization Grant

The trick here is that the authorization server would need to be able to check that the client supplied the
  correct secret as part of the code exchange.
To do this we could supply the hash of the secret to the authorization server ahead of time.
To verify the secret which is supplied during the exchange, 
  we hash it and then compare it to the hash previously supplied.
  
Note: for the unititiated _hashing_ is a one way transformation of some byte into a different set of bytes.
[Learn more](https://www.educative.io/edpresso/what-is-hashing)


What we're now describing is an extension to the Authorization Grant flow called Proof Key Code Exchange (or PKCE or "pixy").
In PKCE parlance, the secret is called a _verifier_ and the hash of that secret is called the _challenge_.

<img src={PkceRough} width="800" />

In this flow, when the user makes the original request to the authorization server,
  in order to begin the authorization flow,
  it supplies all the reular parameters _and the challenge_.
The flow then proceeds exactly like an authorization grant flow:
  user logs in and then gets redirected back to the callback url with the code.
The browser then sends the request to exchang the code _and the verifier_ for the token.

### Tokens

We've talked a lot about the steps involved with OAuth flow, but what of the actual goal?
Stated earlier the goal of OAuth is to authorize our user,
  but from a technical perspective the goal is actually to receive a _token_ which contains.

The token can be used by your application to  determine who the user is (i.e. the user's _identity_).
There's often extra information included with the token about what the user is allowed to do
  and what APIs they are allowed to use (i.e. their _access_).

It's very common for the token to take the form of a JWT Token.
The actual JWT format will be explained more detail in a later section.
For now it's more than enough to know that commonly basically base64 encoded json
  with a special signature that can be used to verify the token's authenticity.

The fields of the json object that we've base64 encoded to make the token are called _claims_,
  and there's a set of common claims that the token will have. For example:

- `sub` - is the _subject_, an identifier of who this token belongs to
- `exp` - is the expirey in epoch seconds
- `aud` - is the _audience_, or which api(s) the token should allow access to
- `scopes` - can describe the permisions the user has been granted

When the client makes the original request to the authorization server,
  they might supply (along with the callback url and challenge),
  a desired _audience_ and desired _scopes_ for eventual token.
Use-case specific logic can be used to determine whether or not a token of
  with such claims can be granted to the particular user.

There's another special claim called `scopes` which is commonly used to describe which
permissions a user is allowed to have.

Since the token serves this dual purpose, 
  it is often customary for this final stage of the flow to actually return two different tokens:
  an _access token_ and an _id token).

### OpenID Connect

One of the very common use cases of OAuth registering for a new site or service
  using information provided by some other social network.
For example, you might have seen on the internet the "Register with Facebook" or
  "Create an account using twitter" features on some sites you have visited.

In these scenarios, users will be taken through an OAuth flow and be provided with
  an access token that could be used to go fetch the information from the social network
  to create an account on the new service.
This might include their full name, email, country, etc.

The group that works to design oauth implementations saw a need to design a common
  set of scopes that could be used to describe this set of information.
It's customary in this case for the client to request a scope of `openid` and doing
  so allows them to request other scopes such as: `email`, `address`, `phone`, etc.
There's also one special claim called `profile` which tells the authorization server to
  generate a token with some default set of many OpenID scopes.

In a broad sense, OAuth 2.0 can be thought of as the mechanism for exchanging authorization
  information and OpenID Connect can be thought of as a common format for that information.

## Implementation

With all that background out of the way,
  here's the real flow in all it's gory detail.

If you skipped ahead to this part of the article because you saw a pretty picture,
  feel free to just start reading here if you'd like.
You can refer to the front-matter as reference.
The rest of this article is going to show the code that implements the flow in the
  diagram below.

<img src={FullFlow} />


This is also the section of the aritcle where we'll start looking at the code.
There's basically three API calls that will be made as part of the flow,
  we'll start by setting up our endpoints:

<Code language="rust">
{`#[actix_web::main]
async fn main() -> std::io::Result<()> {
  let server = HttpServer::new(|| {
    let cors = Cors::permissive();
    App::new()
      .wrap(cors)
      .route("/oauth2/authorize", web::get().to(redir_to_login))
      .route("/oauth2/login", web::post().to(handle_login))
      .route("/oauth2/token", web::post().to(handle_token))
  });
   
  server.bind("127.0.0.1:4000")
    .expect("error binding server")
    .run()
    .await
}
`}
</Code>


## Phase one

This is the phase where we begin the login flow.
In this phase we
- create a _verifier_
- create a _challenge_ from the verifier
- begin a login session with the API
- redirect the browser to the login screen

The very first step of the flow is to create the _verifier_.
Recal that the function of the verifier (along with the code), will be exchanged for a token in Phase Three.
Its form is _a cryptographically random value_, which sounds spooky, but it's effectively a bunch of random bytes.

Note - the fact that the value is cryptographically random is another barrier to entry
  for adopting PKCE in place of implicit grant.
Older browsers don't support the fancy new _crypto_ api.

The _verifier_ and _challenge_ are both generated on the client.
Recall that the challenge is the base64 encoding of the hash of the SHA256 hash of the verifier.
Here's what the code to do this could look like.

<Code language="typescript">
{`export class Verifier {
  constructor() {
    this.array = new Uint8Array(64);
    window.crypto.getRandomValues(this.array);
    this.getChallenge = this.getChallenge.bind(this);
  }
  
  async getChallenge(): string {
    return toBase64(await sha256(this.array));
  }
  
  toString() {
    return toBase64(this.array);
  }
}
  
async function sha256(bytes: Uint8Array): Uint8Array {
  const hash = await crypto.subtle.digest('SHA-256', bytes);
  return new Uint8Array(hash);
}
  
function toBase64(bytes: Uint8Array): string {
  let binary = '';
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
`}
</Code>

_Note:_ the challenge doesn't technically have to be a SHA-256 hash of the verifier
and some implementations allow different hashes or no hash at all. 
In these cases, an additional parameter `challenge_type` is often required.

Once we have a verifier and challenge,
  we gotta get prepared to do some stuff.
We'll need to store the verifier for use in phase three, so that will go into a cookie.

Then we'll send the challenge down to the API server.
When we do that, we'll get redirected to the login page for the beginning of phase two.

There's a few other things we might want to send to the API Server when we do this.
Recall that after phase two,
  we'll get redirected back to the original application before doing phase three.
But, we won't be re-visiting the original application in the interim,
  which means that if the app knows where step two should return to,
  it has to send that info along.
All this to say, we have to send the callback URL.

This is also where we'll send values to control token we eventually receive.
Maybe when you implement this flow for your website,
  you want the token you eventually recieve to have certain scopes,
  or maybe an audience claim.
Recall that the scopes could be used to determine what a user is really allowed to do.
The audience claim could be used determine which APIs the user has access to.

All the parameters we send (challenge, callback_url, scopes and audience),
  will be stored in a special session object.
We'll redirect the user to the login screen with a reference to the unique identifier
  of the session.

<Code language="rust">
{`#[derive(Debug, Deserialize)]
pub struct AuthorizeParams {
    challenge: String,
    callback_url: String,
    audience: String,
    scopes: String,
}
  
async fn redir_to_login(req: HttpRequest) -> HttpResponse {
    let params = web::Query::<AuthorizeParams>::from_query(req.query_string()).unwrap();
    let session_id = Uuid::new_v4().to_string();
    dao::store_session(
        &session_id,
        dao::Session {
            challenge: params.challenge.clone(),
            callback_url: params.callback_url.clone(),
            audience: params.audience.clone(),
            scopes: params.scopes.clone(),
        },
    )
    .await;
    let redirect_location = format!("http://localhost:1234?session_id={}", session_id,);
    HttpResponse::Found()
        .header("Location", redirect_location)
        .finish()
}`}
</Code>

I'll be interacting with a module called `dao` in this which stands for _data access object_.
You can imagine that this is storing the data in some secure, consistent database.
Which database we use isn't necessarily important. For curious readers the implementation
[can be found here](https://github.com/albertlockett/terry-sso/blob/main/server/src/dao.rs).

The code to start this flow from the browser looks like this:

<Code language="typescript">
{`type LoginParams = {
  callbackUrl: string;
  audience?: string;
  scopes?: string[];
};
  
export async function doLogin(params: LoginParams) {
  // store the verifier in the cookie for later use.
  // It expires in 5 minutes, hopefully it doesn't take that long for users to type their password etc.
  const verifier = new Verifier();
  document.cookie = \`terry_auth=\${verifier}; new Date(new Date().getTime() + 300_000); path=/\`;
  
  const challenge = await verifier.getChallenge();
  let queryString = \`challenge=\${challenge}&callback_url=\${params.callbackUrl}\`;
  queryString += \`&audience=\${params.audience ?? ''}\`;
  queryString += \`&scopes=\${params.scopes?.join(',') ?? ''}\`;
  window.location = \`http://localhost:4000/oauth2/authorize?\${queryString}\`;
}
`}
</Code>

If we publish the code to call work through thi flow as an SDK,
  here's how some developer might use the SDK on their site to begin the login flow.

<Code language="javascript">
{`import * as sdk from 'terry-sso-sdk';
  
const button = document.getElementById('login');
button.addEventListener(
  'click',
  function () {
    sdk.doLogin({
      callbackUrl: ` + "`${window.location.origin}/callback`" + `
    });
  },
  false
);
`}
</Code>

## Phase Two:

In the previous step, the user began the login flow by creating some login state 
  and then had their browser redirected to the login page of terrylockett.ca
For our implementation, we'll use a simple html form to accept the user's username
  and password.
In reality these applications could be quite a bit more interesting.
Features on such an application could include MFA or 
  not having the user re-authenticate if they already
  have an active session with the autentication provider.

We're going to keep it simple.
A Simple web form for username and password will do.

Here's the front-end of our login application.
<Code language="typescript">
{`
import React from 'react';
import ReactDOM from 'react-dom';
  
import './styles.scss';
  
function App() {
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get('sessionId');
  const error = params.get('error')
  return (
    <div className="login-page">
      <h1>Login</h1>
      <form method="post" action="http://localhost:4000/oauth2/login">
        <label>username</label>
        <input type="text" name="username" />
        <br />
        <label>password</label>
        <input type="password" name="password" />
        <br />
        <input type="hidden" name="session_id" value={sessionId} />
        <button>OK</button>
        <br />
        <Error error={error} />
      </form>
    </div>
  );
}
  
ReactDOM.render(<App />, document.getElementById('app'));
`}
</Code>

Recall that in the previous phase we sent several parameters to the authentication server
  describing what kind of token the user wishes to receive
  and where they wish to be redirected back to after a successful login.
When the user submits the form,
  we'll need to somehow retrieve those parameters.
Hence, we also submit the _session_id, which is just some id that can be used to
  retrive the other parameters later on.
This concept of a session ID is more of an implementation detail than anything else,
  i.e, it's not necessaily part of the Oauth spec.

There's also an error component here which will handle the error states described below.

Finally, no respectable web developer would put his site on the internet without some _style_:
<Code language="css">
{`
body {
  margin: 0;
  top: 0;
  background: lightblue;
}
`}
</Code>

Here's the code the handles the response when the user clicks _OK_ to submit their credentials.
The purpose of this handler is to eventually generare a code that can be exchanged for a token
  and then send a response that will redircet the browser back to the callback url.
But that only happens if the request was successful. 

Before we can do that, we need to perform some checks:
- check if the session ID is valid (not some garbage input)
- check that the username and password are correct (aka _authenticate_ the user)
- check that this user is allowed to create the type of token they are attempting to create

The third check is really an application specific detail,
  but I added it here to show where this kind of business logic could be built into 
  an Oauth flow implementation.
For example, maybe in the original request the user requested a scope of `deleteAllRepositories`
  with an audience of `my-source-control-system.example.com`.
Probably only admin users are allowed to do something like this, so in this case we'd deny the
  request.

In each case, we'll also want to handle the failed check differently.

If the user sends an invalid session ID,
  we'll have ot keep them on the login screen but send them a message that something
  invalid has happened.
There's no other option - we couldn't redirect them back to the original app
  because the callback url a session attribute and the session we can't find.
To do this, we'll 302 the user to the login page and add the querystring parameter
  `?error=invalid_session`

Similarly, if the user enters invalid credentials,
  we'll keep them on the login screen
In this case however we want to show a different error -
  namely that the credentials they entered were not correct.

When we do authenticate the user, now we can check that they're allowed to be issued
  the token they have asked for when they initiated the session.
In this case, it was the original application that made the request to initiate the
  session, so we'll want to let that application know that what they sent was invalid.
This means, we'll 302 back to the original application via the callback url, 
  and we'll also return an error code in the query string.
  `?error=not_allowed_scopes` or `?error=not_allowed_audience`

Finally, if we haven't hit any of our edge cases we'll create a new code,
  store the some state that associates the code, the user and the session,
  and return the code to the original application by adding it to the query string
  in the redirect to the callback url.

<Code language="rust">
{`
#[derive(Debug, Deserialize)]
pub struct PasswordFormValues {
    username: String,
    password: String,
    session_id: String,
}
  
async fn handle_login(params: web::Form::<PasswordFormValues>) -> HttpResponse {
     // check if the session is valid
    let session_op = dao::get_session(&params.session_id).await;
    if session_op.is_none() {
        let location = format!(
            "http://localhost:1234?session_id={}&error={}",
            params.session_id, "invalid_session"
        );
        return HttpResponse::Found().header("Location", location).finish();
    }
    let session = session_op.unwrap();
  
    // check if the password is valid ...
    if !is_valid_password(&params).await {
        let location = format!(
            "http://localhost:1234?session_id={}&error={}",
            params.session_id, "invalid_credentials"
        );
        return HttpResponse::Found().header("Location", location).finish();
    }
  
    // check that the user has requested allowed scopes
    let allowed_scopes = dao::get_allowed_scopes(&params.username);
    let mut not_allowed_scope_requested = false;
    session.scopes.split(",")
        .for_each(|scope| {
            not_allowed_scope_requested |= !allowed_scopes.contains(&scope.to_string());
        });
    if not_allowed_scope_requested {
        let callback_url = format!("{}?error=not_allowed_scopes", session.callback_url.clone());
        return HttpResponse::Found()
            .header("Location", callback_url)
            .finish();
    }
  
    // check that the audience is allowed
    let audience_restrictions = dao::get_audience_restrictions(&params.username);
    if audience_restrictions.is_some() && !audience_restrictions.unwrap().contains(&session.audience) {
        let callback_url = format!("{}?error=not_allowed_audience", session.callback_url.clone());
        return HttpResponse::Found()
            .header("Location", callback_url)
            .finish();
    }
  
    // store the code and redirect user w/ code
    let code = format!("{}", Uuid::new_v4());
    dao::store_code(&code, &params.username, &params.session_id).await;
    let callback_url = format!("{}?code={}", session.callback_url.clone(), code);
    HttpResponse::Found()
        .header("Location", callback_url)
        .finish()
}
   
/// check that the user supplied valid credentials
/// don't do like this irl - it's not a very smart way to check creds
async fn is_valid_password(params: &web::Form::<PasswordFormValues>) -> bool {
  let valid_credentials: bool;
  let password = dao::get_password(&params.username).await;
  println!("{:?}", password);
   
  if matches!(password, None) {
    valid_credentials = false; // no user found
  } else {
    valid_credentials = params.password == password.unwrap();
  }
  return valid_credentials;
}
`}
</Code>

Here's how our first two error messages are handled in the browser.
<Code language="typescript">
{`
import React from 'react';
  
type ErrorProps = {
  error: string;
};
  
const errorMessages = {
  invalid_credentials: 'Your password and/or username is wrong!',
  invalid_session: 'Invalid session state!'
};
  
export default function Error(props: ErrorProps) {
  if (!props.error) {
    return null;
  }
  
  return (
    <div className="error">
      <b>Error happen:</b>
      <br />
      <em>{errorMessages[props.error]}</em>
    </div>
  );
}
`}
</Code>

We'll be handling the other error states later on.

## Phase Three

In the third phase of our oauth flow, we have been redirected to the callback url and 
  we are ready to exchange the code for the token.
From the client's perspective,
  this is a relatively simple procedure.
We just send the code to the server, along with the verifier we created during phase one.
Recall, the verifier was stored in a cookie!

<Code language="typescript">
{`
type ExchangeCodeParams = {
  code: string;
};
  
type ExchangeCodeResponse = {
  access_token: string;
  id_token: string;
};
  
export async function exchangeCode(
  params: ExchangeCodeParams
): ExchangeCodeResponse {
  const verifier = getVerifierFromCookie(document.cookie);
  const response = await fetch('http://localhost:4000/oauth2/token', {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ code: params.code, verifier })
  });
  const result = await response.json();
  return result;
}
  
function getVerifierFromCookie(cookie): string {
  const verifier = cookie
    .split(';')
    .map((s) => s.trim())
    .find((c) => c.startsWith('terry_auth'))
    .replace('terry_auth=', '');
  return verifier;
}
`}
</Code>

Then we invoke this function on our callback page:
<Code language="typescript">
{`
import * as sdk from 'terry-spa-sdk';
  
let token = '';
  
(async function () {
  if (!window.location.pathname.startsWith('/callback')) {
    return;
  }
  
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const error = params.get('error');
  if (error) {
    handleError(error);
  } else {
    const result = await sdk.exchangeCode({ code });
    token = result.access_token;
  }
})();
  
async function handleError(error) {
  console.error(error);
  document.addEventListener('DOMContentLoaded', function () {
    const errorContainer = document.getElementById('error');
    errorContainer.innerText = 'An error occurred';
  });
}
`}
</Code>

And here's the backend code that would handle that request:

<Code language="rust">
{`
async fn handle_token(params: web::Json<TokenFormValues>) -> HttpResponse {
    let code_op = dao::get_code(&params.code).await;
    if code_op.is_none() {
        return HttpResponse::BadRequest()
            .header("content-type", "application/json")
            .body("{\"error\": \"invalid_code\"}");
    }
    let code = code_op.unwrap();
  
    let session_id = code.session_id;
    let session_op = dao::get_session(&session_id).await;
    if session_op.is_none() {
        return HttpResponse::BadRequest()
            .header("content-type", "application/json")
            .body("{\"error\": \"invalid_state\"}");
    }
    let session = session_op.unwrap();
  
    let challenge = session.challenge;
    if !is_valid_verifier(&params.verifier, &challenge) {
        return HttpResponse::BadRequest()
            .header("content-type", "application/json")
            .body("{\"error\": \"invalid_verifier\"}");
    }
  
    let access_token = token::generate_token(&code.user, &session.audience, &session.scopes);
    HttpResponse::Ok()
        .header("content-type", "application/json")
        .body(format!("{{\"access_token\": \"{}\"}}", access_token))
}
  `}
</Code>


What's interesting here that the token is recevied,
  so it behoovs to talk about what is the token and what it represents
As metioned eariler the token is used in a dual sense to validate whow the user is 
  and what they are allowed to do.

First, let's talk about the token format.
As was mentioned, it more/less takes the form of base64 encoded JSON.
```
$ node -e '
    const payload = { sub: `albertlockett` };
    console.log(JSON.stringify(payload))
' | base64
# eyJzdWIiOiJhbGJlcnRsb2NrZXR0In0K
```

I say "more/less" because it really has some additional structure - 
3 segements separated by the '.' character:
```
<header>.<payload>.<signature>
```

The header and payload are base64 encoded json.
The payload is really what contains the content
  of the token.
The header contains some metadata about the token
  such as information about what signing algorithm
  was used for the signature.

Speaking of the signature, what is that?
Well, considering the simple format of the token
  it is coneivable that someone could easily
  just spoof a token.
We need to add something to it that can make it
  so the client can know it's a valid token.
We could add some kind of ID and the validator (Server)
  could call back to our auth service to chcek,
  but then we'd DDOS our auth service depending on what
  kind of fucked thing our users did w/ their token.
Luckily this is a solved problem,
  we use the signature.

Signing the token (and JWT token mechanics in general) is a relatively
  broad topic, but luckily there's a good book on it.
  https://auth0.com/resources/ebooks/jwt-handbook


Here is a basic rundown:
```
signature = base64(signingAlgorithm(`${header}.${payload}`))
```

There are different versions of the signing
  alorithm that are best to use depending on the
  use case.

The most common signing algorithm is HS256,
  aka HMAC  + SHA256 which is a symmetric signing
  algorithm.
It uses a secret, which the token profider (the
  authorization servier) must know to sign the
  token AND the validate (your API) must know to
  the token.
It's called symmetric because anyone who knows the secret
  could create and sign tokens - 
  e.g., because the API that validates the token knows the
  secret, it could theoretically also create and sign it's
  own tokens.
HS256 is the most common signing algorithm and often
  when you're signing up with some api that does
  oauth they'll be providing you with a client ID/
  secret pair, where the secret in that pair is what
  is used in the HMAC algorithm to sign the token.

I'm not interested in managing client ID / secrets
  for this project but luckily there's another
  algorithm that suits the use case.
RS256 aka RSA + SHA256 is an asymetric signing 
  algortithm where the private key is used to
  sign the token and anyone with the public key
  can verify it's authenticity.

So that's what we'll be using here.
I'll create a super secret private key
  and have the authorization server be
  using that for signing the keys.
And I'll mkae the public key publicly available
  for validating the token.

Whatever signing algorithm you choose,
  there's going to be some business logic around
  things like
  making hte secrets available to api consumers for
  hmac or roating key pairs for RS256.

Also, I understand that we're straying a bit from
  information on oauth and I debated whether to 
  even include the previous section.
But I figured that - what use is writing all this
  stuff about oauth if no one knew what to do with
  the output or why they're able to do it.
This whole section has really a cursory introduction to JWTs
  and to get a more thorough overview, consult the book liked above.

Anyway, the code to do all this signing and funky stuff
  is tricky to get right but luckily there are some good open source
  libraries that can take care of it for us.
Here's how the code looks to create the token using the excellent
  jwt_simple crate.

<Code language="rust">
{`
use jwt_simple::prelude::*;
  
#[derive(Serialize, Deserialize)]
struct CustomClaims {
    scopes: String,
}
  
pub fn generate_token(subject: &str, audience: &str, scopes: &str) -> String {  
    // variable 'PEM' is the private key as a &str
    let key_pair = RS256KeyPair::from_pem(PEM).unwrap();
  
    let exp = Duration::from_millis(30 * 60 * 1000);
    let custom_claims = CustomClaims {
        scopes: String::from(scopes),
    };
    let token = key_pair
        .sign(
            Claims::with_custom_claims(custom_claims, exp)
                .with_subject(subject)
                .with_audience(audience)
                .with_issuer("terrylockett.ca"),
        )
        .unwrap();
    return token;
}
`}
</Code>

finally, presumably the client that consumes this
  token could send it down to the api you're
  trying to add auth to as part of a header

<Code language="javascript">
{`const button = document.getElementById('button');
button.addEventListener(
  'click',
  async function () {
    const res = await fetch('http://localhost:4001/v1/data', {
      mode: 'cors',
      headers: {
        Authorization: \`Bearer \${token}\`
      }
    });
    const text = await res.text();
    console.log(text);
  },
  false
);
`}
</Code>

here's a presumptive check on how you'd
  go about verifying the token
<Code language="javascript">
{`
const express = require('express');
const cors = require('cors');
const jwt = require('jsonwebtoken');
 
const app = express();
app.use(cors());
 
const publicKey = \`-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA8f/E4EJRrr8jgn1qRJLb
rJjpFKuc0haRb9HFpc5fJeBq2xfJpu5gnuKsFInt5vNJL1Zho17VCg0LYC3XzCHU
ywlQfjMkzA1BrSrDD58yx4eWwSlIyR6r9dikJYJm6Ho5c4+wCKga+2YFrgBZp9BK
UHHCWbi8otE+rdPL/8K+mxnqt/R4Pm2QvFBRrJ433m5nZx024Fr9DMP59Sl0QjOC
fxzWr33Juyr5nURUxl0IcSg/BjPqQN4j8qTeADLB/BEQVvfJuBc1dy0IpOIhnrkM
9Gk0GfCePKYvFCHn90yROZvriukcFMFaNQtbNkEHr2Uh4VVDYI/8FgHGSWqPr49J
GwIDAQAB
-----END PUBLIC KEY-----\`;
 
app.get('/v1/data', function(req, res) {
  const authHeader = req.get('Authorization');
  const token = authHeader.split(' ')[1];
 
  try {
    jwt.verify(token, publicKey);
    res.status(200);
    res.json({ data: 'OK' })
  } catch (e) {
    res.status(403);
    res.json({ error: 'unauthorized' })
  }
});
 
app.listen(4001, function() {
  console.log('listening on port 4001');
});
`}
</Code>

## Conclusion

A lot has been said about OAuth in this article and I hope that you have found it interesting to read.
I hope that it has helped you to learn more about OAuth.
It's important to learn about OAuth due to it's ubiquity on the internet.
Learning the ins and outs of OAuth can help you make better architectural decisions about
  your browser based applications.

All that said, not everyone is such a fan.
It has been said that OAuth is ["absolute dog's breakfast"](https://en.wikipedia.org/wiki/OAuth#cite_ref-22).
I don't know about all that - I have never eaten dog's breakfast.

If you'd like to let me know what you think of OAuth or dog's breakfast, I can be reached at
[albert.lockett@gmail.com](mailto://albert.lockett@gmail.com).


## Bibliography / Further Reading
- [OAuth 2.0 and OpenID Connect (in plain English)](https://www.youtube.com/watch?v=996OiexHze0) - 
  the best introductory youtube video on this subject
- [_PKCE: What and Why?_ ](https://dropbox.tech/developers/pkce--what-and-why-#:~:text=%E2%80%9CPKCE%20(RFC%207636)%20is,to%20access%20their%20Dropbox%20data.)
- [OAuth.net Impllicit Grant Page](https://oauth.net/2/grant-types/implicit/)
  including a great [youtube video](https://www.youtube.com/watch?v=CHzERullHe8) about why you should prefer PKCE
- [The original Oauth2 spec for PKCE](https://oauth.net/2/pkce/)
- [OpenID Connect Guide](https://auth0.com/docs/authenticate/protocols/openid-connect-protocol)
- [OpenID Connect Scopes](https://openid.net/specs/openid-connect-basic-1_0.html#Scopes)