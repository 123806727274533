import Code from '../../Code'
import PngWritePath1 from './write_path_1.png'
import PngWriteFlush from './write_flush_fixed.png'

# albertdb

_This arcicle is work-in-progress (WIP).
Some sections will be more fleshed out than others and some sentences/paragraphs
are placeholders for future work. Please excuse mistakes, omissions and factual
errors._

Welcome to albertdb, a key-value database.
Its design is inspired by other LSM databses like Scylla, Dynamo and RocksDB.

I wrote this as a a side-project while teaching myself Rust and also to
  demonstrate some competance in database development.

When I started this project, I had very little experience with Rust but I had
  just finished reading _Programming Rust_ by Bandy et al. 
I also had no experience with database development, but I had also recently
  finished reading _Database Internals_ by Alex Petrov (a good read).
I was looking for a project to help solidify the concepts.

You're reading an article about a database written in rust by someone
  with no prior practical experience with rust or databse development.
Enjoy!

You might also enjoy reading this article if you are interested in learning more
  about database internals.

[The code for albertdb can be found on my github](https://github.com/albertlockett/albertdb)

There are code snippets used in the article for demonstration purposes.

## Engine

This section will describe the read and write paths of the database.

It will reference many of the data structures that have been used to build
  this database but not in great detail.
This section is mostly focused on how the pieces fit together at a high level.
The individual components will be described in better detail in other sections.

_TODO somewhere could show the code for the engine or at least add more snippets_.

### Write Path

The database receives key-value records to be written consisting of arbitrary
  arrays of bytes.
This might seem a bit limiting as other databases allow operations on friendlier
  primitives such as strings and numeric values,
  and indeed better use of generics could support this.
My thinking on using byte arrays was that it keeps the code in the engine a bit 
  simplier
Also,  making the application responsible for serializing and deserializing the
  data isn't without precident.
Many redis clients do the same thing.

<Code language="rust">
{`pub fn write(&mut self, key: &[u8], value: &[u8]) {`}
</Code>

The record is then written to an in-memory balanced tree called a _memtable_ and
  it is also peristed in an on-disk file called the write ahead log (_WAL_).
<Code language="rust">
{`    self.writable_wal.write(key, Some(value)).unwrap();
    self.writable_table // <-- memtable
        .insert(key.to_vec(), Some(value.to_vec()));`}
</Code>

The WAL will be used to recover the memtable in case of a database crash.

<img src={PngWritePath1} />

#### Memtable Flush

The memtable cannot grow indefinity, otherwise it would use up all the
  memory on the system.
When the memtable reaches a certain threshold, it is flushed to an on-disk
  data structure called an _sstable_.

<Code language="rust">
{`    if self.writable_table.size() > self.config.memtable_max_count {
        self.flush_writable_memtable();
    }`}
</Code>

Once the memtable begins being flushed, new values are no-longer written to it.
A new memtable is created and it becomes the writable memtable.
The flushing memtables remain avaiable in memory for the read path while
  the flush is in progress.

Once the flush is complete, the sstable becomes available for the read path.
The flushing memtable is no-longer needed and it is dropped from memory.
The WAL is also no-longer needed and it is also deleted.

<img src={PngWriteFlush} />

### Read Path

Reads will check each data structure that could contain the data in order of
  how recently the data was written.
This ensures we always read the freshest value.

First, the current writable memtable is checked.
If the data is not found, each flushing memtable is checked until until the data
  is found.
If the value cannot be found in any of the memtables,
  the sstables will be checked next in order of how recently they were created.

_TODO there's a bug in this algorithm that an older memtable could finish flushing
after the a newer one?_
_TODO add a picture of the readpath_
_TODO add a picture of the code going through these checks_

### Deletes

Given the explanation of the readpath, consider if simply deleting a node from
  the memtable would delete the value from the database.
The answer is "no".
If the value is written into the memtable and then the memtable is flushed,
  the old value for this key would still be present in the flushed memtable.
We need to add a record to mark the value as explicitly deleted.
This is called a tombstone.

_TODO we could put a picture of this_

## Memtables

Memtables are an in-memory balanced tree that are used to buffer writes before
  they are flushed to disk as immutable sstables.

There are many kinds of balanced binary trees that could be used for this.
B-Trees would have been more traditional, and a Red-Black Tree or 2-3 tree could
  also have worked but albertdb uses a treap with randomization to ensure
  balancing.
This implementation was chosen for it's simplicity (a simple data structure was
  needed as I was just a beginner at rust when I started this).

Treaps are binary trees that are balanced in the left-right direction 
  (like a binary search tree) using one measure and in the up-down direction 
  (like a heap) using another. _Tree + Heap = treap_
In this case, our databases keys are used to balance the tree left to right,
  and a random number is used to balance the tree in the up-down direction which
  is called the _priority_.

Using a random variable ensures that the tree will be balanced on average.
This can be shown mathemetically that the maximum expected value of the depth of
  any node in the tree proportional to the logarithm of the number of the number
  of nodes
The details are a bit gory but we can also think about it intuitively.
As more and more nodes are added,
  if we are randomly choosing at which depth to insert the nodes, 
  then eventually it becomes less likely we'll keep adding to the top
  or the bottom.
I don't intend for this to be a post about randomized treaps.
An excellent reference to learn more is _Advanced Algorithms and Data Structures_
  by Marcello La Rocca.

_TODO if this turns into a long long document, could add some proofs here_

### Implementation

It's interesting to look at how this datastrcture is really built using Rust.

Thanks to Rust's ownership model, these kinds of data structures that
  constructred from nodes that have pointers to other nodes are notoriously
  difficult for beginners to build from scratch.
I had originally intended for this to be an article just about albertdb,
  but anyone reading this for the purpose of learning more about rust might find
  this tangent helpful.

Here's how the node is defined:

<Code language="rust">
{`pub struct Node {
    pub key: Vec<u8>,
    pub value: Option<Vec<u8>>,
    pub priority: f64,
    pub left: Link,
    pub right: Link,
    pub parent: Link,
}
 
pub type Link = Option<Arc<RwLock<Node>>>;
`}
</Code>

Key and Value are implemented as `Vec<u8>`, an array of bytes as would be expected.
Value uses the `Option` enum, where the `None` would value would represent a
  deleted value (a tombstone).

_Option in Rust is an enum with two values, `None` and `Some(<T>)`.
None means there is no value, and Some means there is a value.
This is simliar to `java.util.Optional<T>` in Java.
The golang equivalent would be returning a pointer and a nil pointer represents no value_.

For the up-down heap invariant, we use  `priority` which is a random 64bit floating
  point (a "long" in normal languages).

The fields that represent relationships with other nodes is where things get
  complicated/interesting!
For left, right and parent nodes in the tree, we use the `Link` type.
This type is like an ogre, it has layers that must be unwrapped to be understood.

First, if the relationship doesn't exist, the option will be `None`.
In this case, leaf nodes would have `left: None` and/or `right: None`, and the root
  node would have `parent: None`.

The next layer is the `Arc` type which is a reference counted pointer that can
  be passed between threads (rc = reference counted, A = atomic).
No two nodes can "own" another node in rusts ownership model, but each can own
  a clone of the pointer.
A reference counting pointer is used because multiple references must be
  created for the same node (i.e, a node's parent will point to it as the 
  left/right property and the node's child will point to it as the parent).
Multiple threads will be operating on our memtable at once (i.e. one thread
  could be flushing the memtable while another is reading from it), so a 
  pointer that can be passed between threads must be used.

The final layer is the `RwLock`, which is a Read/Write lock that can be shared
  across threads.
Usually in Rust, to have write access to a data structure you would borrow the
  a mutable reference to the node whereas many non-mutable references could be
  borrowed simultaneously for reading.
But because our memtable and the nodes within it are shared across many threads,
  guarantees about how many mutable references exist cannot be made and so rust
  doesn't let us do it.
The RwLock is our way around this.
It has methods to give us one mutable reference to the Node which will wait 
  if non mutable borrows have been made, and vice-versa.

An excellent primer on all these data structures will be available in 
_Rust Atomics and Locks_ by Mara Bos which is currently in the pre-release
  state on [o'relley.com](https://learning.oreilly.com/library/view/rust-atomics-and/9781098119430/ch01.html)

The structure of the memtable itself is a bit simpler -- it is a refernece to
  the root node plus some metadata about the table itself such as how many nodes
  it contains and some unique identfier.
<Code language="rust">
{`pub struct Memtable {
    root: Link,
    size: u32,
    pub id: String,
}`}
</Code>

_TODO could possibly give some descriptions of insert & delete algorithms_.

## SSTable

The sstable is the on-disk data structure that stores the data for our db.
The "SS" in sstable stands for "Sorted String", which means that the values
  in the sstable are stored in an order that is sorted by the key.
When we flush the sstable to disk, we can build it from the memtable by
  traversing it using a standard DFS traversal.

Each sstable is made up of two files, the metadata file and the data file.
The memtable ID becomes the sstable ID and is postfixed to the file name.
```
$ ls /tmp
sstable-data-2037628040
sstable-meta-2037628040
```

### Data File

SStables are organized into blocks, and each block contains multiple key/value pairs.
Within a block, keys and values are stored in sorted order, and each block is sorted
by the minimum key.

This is how the data would be laid out within a block:
```
[
  flags(1byte)|
  key_length(4 bytes)|
  key1(byte[])|
  value_length(4 bytes)|
  value1(byte)
]
[flags|key_length|key2|value_length|value2]
[flags|key_length|key3|value_length|value3]
...
```

_TODO this might change with https://github.com/albertlockett/albertdb/issues/10_

Each block will be is compressed and that is what is stored in the sstable
```
[compress(block1)]
[compress(block2)]
...
```

### Metadata File

The metadata file is yaml and is formatted like follows:

<Code language="yaml">
{`
 # blocks contains metadata about each block in the data file
 blocks:
    # count is the number of key value pairs in the
  - count: 2
    # size is the size of the block uncompressed
    size: 126
    # size_compressed is the size of the compressed block in the file
    size_compressed: 50
 
    # start key is the array of bytes in the first key in the block
    # for example "a3" = [0x61, 0x33] = [97, 51]
    start_key:
      - 97
      - 51
     # start offset is the offset in the data file of the block.
    start_offset: 0
  
  # next block ...
  - count: 2
    size: 39
    size_compressed: 64
    start_key:
      - 97
      - 97
      - 115
      - 100
      - 102
    start_offset: 50
  # this is the time the sstable was written
  timestamp: 1656967171168
  # this is the level of the sstable (used in leveled compaction strategy)
  level: 0
  
  # ... bloom_filters follows
`}
</Code>

On the read path, we are able to do binary search on the key to determine 
  which block may contain a given key.
Once the block is known, we can allocate a buffer of known size by knowing
  the uncompressed block size.
The block can be uncompressed into the buffer and then the data is iterated
  to find the key/value pair if it exists.

### Bloom Filter

Knowing which block contains the data is great but it is impossible to know
  if the key is in the block unless it is decrompessed and iterated.
By way of optimization, we have bloom filters that can be used to determine 
  with certainty that a given table does not contain the key.
That way the sstable can be skipped entirely on the readpath.

A bloom filter is a datastructure that is a sequence of bits.
To check if some key is in the bloom filter, we hash the key and then use that
  hash to indentify a bit in the array.
If the bit is one, the table may contain the key (or it may contain a 
  different key with the same hash)

Example - in the simple example below we know "a" is not in the table because 
  `bloom_filter[2]` is `0`. 
```
key = "a"
hash(key) = 2
bloom_filter = [0,1,0,0,1,0]
```

In the `albertdb` implementation, the bit array is implemented as a series of
  128 bit integers called segments.
e.g, a bloom filter with 1024 bits would have 8 segments.

Typically more than one hash will be computed and each to check if the table
  may contain the key, the filter must have a `1` in the position for each hash.
To create multiple hashed values, double/triple hasing is used.
e.g. each subsequent hash is a combination of two hashes of the previous hash
  plus some offset.
This is a simple way to avoid has collisions.
A good reference for further information about bloom filter implementation is
  _Advaned Data Structures and Algorithms_ by Marcello la Rocca.

<Code language="rust">
{`
let mut indices = vec![0; self.num_hashes as usize];
for i in 0..self.num_hashes {
    let hash_murmur3 = fasthash::murmur3::hash32_with_seed(key, self.seed) as u64;
    let hash_city = fasthash::city::hash32_with_seed(key, self.seed) as u64;
    let hash_num = i as u64;
  
    // double hash to avoid collisions
    let hash = hash_murmur3 + hash_num * hash_city + hash_num * hash_num;
    indices[i as usize] = (hash % self.size as u64) as usize;
}
`}
</Code>

The bloom filter section of the sstable meta file looks like the following:

<Code language="yaml">
{`bloom_filter:
  # this is how many hashes are computed for each key
  num_hashes: 3
  # this is the size of the bit array in the boom filter
  size: 2048
  # this is the randomly chosen seed for our hash functions
  seed: 2142
  # these are the 128 bit integers which are used as bit arrays
  segments:
    - 0
    - 0
    - 4294967300
    - 140737488355328
    - 16777216
    - 0
    - 262144
    - 0
    - 0
    - 1024
    - 162259276829213363391578010288128
    - 166153499473114484112975882669260800
    - 16
    - 4951760157141521099596496896
    - 0
    - 1298074214633706907132624082305024
`}
</Code>





